/* eslint-disable no-console */
import { Cookie } from '@common-vue/util/src/modules/cookie';
import { getChildProperty } from '@core/util/object';
import logger from '@common-vue/util/src/modules/logger';

const $http = require('@vue-util/http');

const getRewardPoints = async () => {
  try {
    const UID = Cookie.get('bloomingdales_online_uid');
    const GUID = Cookie.get('bloomingdales_online_guid');
    const signedIn = Cookie.get('SignedIn');
    const url = '/xapi/loyalty/v1/accountsummary?_pageType=Home';
    const headers = {
      'x-macys-uid': UID,
      'x-macys-userguid': GUID,
      'X-Macys-SignedIn': signedIn,
      'X-Requested-With': 'XMLHttpRequest',
    };
    const options = {
      headers,
      timeout: 5000,
    };
    let loyaltyData = await $http.get(
      url,
      options,
    );
    if (!loyaltyData.errors) {
      loyaltyData = {
        tierName: getChildProperty(loyaltyData.data, 'loyalty.tierInfo.tierName'),
        rewardCardBalance: getChildProperty(loyaltyData.data, 'loyalty.rewardCardBalance'),
        totalAvailablePoints: getChildProperty(loyaltyData.data, 'loyalty.points.totalAvailablePoints'),
        balanceAmountToUpgrade: getChildProperty(loyaltyData.data, 'loyalty.tierInfo.spendToNextUpgrade'),
      };
    }
    return loyaltyData;
  } catch (error) {
    logger.error(error);
    return error;
  }
};

export default getRewardPoints;
