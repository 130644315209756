/* eslint-disable import/prefer-default-export, import/no-cycle, no-console */
import querystring from 'qs';
import logger from '@common-vue/util/src/modules/logger';
import { userInfo } from '../../../utils';

const $http = require('@vue-util/http');

export const getRecommendations = async ({
  zone, maxRecsCount, brand, deviceType,
}) => {
  const brandUppercase = brand.toUpperCase();
  const maxRecsProsCount = maxRecsCount;

  try {
    const params = querystring.stringify({
      categoryId: '3536',
      noDefaultRecs: true,
      maxRecommendations: maxRecsProsCount,
      productId: '-9',
      requester:
        deviceType === 'PHONE'
          ? `${brandUppercase}-${brandUppercase.charAt(0)}MEW`
          : `${brandUppercase}-NAVAPP`,
      context: zone,
      zone,
      visitorId: userInfo.visitorId(brand),
      adobeVisitorId: userInfo.adobeVisitorId(),
      countryCode: userInfo.countryCode(),
      zipCode: userInfo.zipCode(),
      stateCode: userInfo.stateCode(),
      customerId: userInfo.customerId(brand),
      timeout: 15000,
    });
    const headers = {
      'Content-type': 'application/x-www-form-urlencoded',
    };

    const response = await $http.post(
      '/sdp/rto/request/recommendations',
      params,
      headers,
    );
    if (response.errors || response.Error) {
      throw new Error(
        response.errors
          ? response.errors.error[0].message
          : response.Error.message,
      );
    }
    return response;
  } catch (error) {
    logger.error(error);
    return error;
  }
};
